import BasePlugin from '../BasePlugin'

export default class PublishPublicDiscussionCommand extends BasePlugin {
  async execute () {
    let publicDiscussionId = {
      'publicDiscussionId': this.context.getModel().id
    }
    let recordId = this.context.getModel().id
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/PublishPublicDiscussionCommand`,
      publicDiscussionId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).catch((error) => {
      switch (error.response.data.code) {
        case 'public_discussion_not_found':
          this.context.$message.error('Запись публичных слушаний не найденна!')
          break
        default:
          this.context.$message.error('Неизвестная ошибка')
          break
      }
    }).then((response) => {
      this.context.$msgbox({
        type: 'info',
        message: 'Успешно опубликованно!'
      })
      this.context.getCard().$emit('cancelChanges')
      done()
    })
  }
}
